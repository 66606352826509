import React from 'react'
import Page from '../components/page'
import Card from '../components/card'

const NotFoundPage = () => (
  <Page authenticated={false}>
    <div className="page__responsive">
      <Card>
        <div className="card--inner">
          <h1>NOT FOUND</h1>
          <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
        </div>
      </Card>
    </div>
  </Page>
);

export default NotFoundPage
